export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID ?? "";

export const gtagPageView = (url: string) => {
  window.gtag("event", "page_view", {
    page_path: url,
  });
};

export const gtagTextCommentView = (eventId: string) => {
  window.gtag("event", "text_comment_view", {
    "bonbon:event_id": eventId,
  });
};

export const gtagTextCommentSend = (eventId: string) => {
  window.gtag("event", "text_comment_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagTextCommentClick = (
  eventId: string,
  from: "timeline" | "feedback-point"
) => {
  window.gtag("event", "text_comment_click", {
    "bonbon:event_id": eventId,
    "bonbon:from": from,
  });
};

export const gtagTextCommentDelete = (eventId: string) => {
  window.gtag("event", "text_comment_delete", {
    "bonbon:event_id": eventId,
  });
};

export const gtagAudioCommentClick = (
  eventId: string,
  from: "timeline" | "feedback-point"
) => {
  window.gtag("event", "audio_comment_click", {
    "bonbon:event_id": eventId,
    "bonbon:from": from,
  });
};

export const gtagAudioCommentDelete = (eventId: string) => {
  window.gtag("event", "audio_comment_delete", {
    "bonbon:event_id": eventId,
  });
};

export const gtagAudioCommentPlay = (eventId: string, currentTime: number) => {
  window.gtag("event", "audio_comment_play", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentPlaying = (
  eventId: string,
  currentTime: number
) => {
  window.gtag("event", "audio_comment_playing", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentWaiting = (
  eventId: string,
  currentTime: number
) => {
  window.gtag("event", "audio_comment_waiting", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentSeeking = (
  eventId: string,
  currentTime: number
) => {
  window.gtag("event", "audio_comment_seeking", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentSeeked = (
  eventId: string,
  currentTime: number
) => {
  window.gtag("event", "audio_comment_seeked", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentEnded = (eventId: string, currentTime: number) => {
  window.gtag("event", "audio_comment_ended", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentPause = (eventId: string, currentTime: number) => {
  window.gtag("event", "audio_comment_pause", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentRateChange = (
  eventId: string,
  currentTime: number,
  playbackRate: number
) => {
  window.gtag("event", "audio_comment_rate_change", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
    "bonbon:playbackRate": playbackRate,
  });
};

export const gtagAudioCommentVolumeChange = (
  eventId: string,
  currentTime: number,
  volume: number
) => {
  window.gtag("event", "audio_comment_volume_change", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
    "bonbon:volume": volume,
  });
};

export const gtagAudioCommentStalled = (
  eventId: string,
  currentTime: number
) => {
  window.gtag("event", "audio_comment_stalled", {
    "bonbon:event_id": eventId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagAudioCommentSend = (eventId: string) => {
  window.gtag("event", "audio_comment_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagVideoPlay = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_play", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPlaying = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_playing", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoWaiting = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_waiting", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoSeeking = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_seeking", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoSeeked = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_seeked", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoEnded = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_ended", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPause = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_pause", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoRateChange = (
  videoId: string,
  currentTime: number,
  playbackRate: number
) => {
  window.gtag("event", "video_rate_change", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
    "bonbon:playbackRate": playbackRate,
  });
};

export const gtagVideoVolumeChange = (
  videoId: string,
  currentTime: number,
  volume: number
) => {
  window.gtag("event", "video_volume_change", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
    "bonbon:volume": volume,
  });
};

export const gtagVideoStalled = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_stalled", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoTimeUpdate = (videoId: string, currentTime: number) => {
  window.gtag("event", "video_time_update", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPlay10Percent = (
  videoId: string,
  currentTime: number
) => {
  window.gtag("event", "video_play_10percent", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};
export const gtagVideoPlay30Percent = (
  videoId: string,
  currentTime: number
) => {
  window.gtag("event", "video_play_30percent", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPlay50Percent = (
  videoId: string,
  currentTime: number
) => {
  window.gtag("event", "video_play_50percent", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPlay70Percent = (
  videoId: string,
  currentTime: number
) => {
  window.gtag("event", "video_play_70percent", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoPlay90Percent = (
  videoId: string,
  currentTime: number
) => {
  window.gtag("event", "video_play_90percent", {
    "bonbon:video_id": videoId,
    "bonbon:current_time": currentTime,
  });
};

export const gtagVideoFeedbackToggleMute = (
  videoId: string,
  userId: string,
  mute: boolean
) => {
  window.gtag("event", "video_feedback_toggle_mute", {
    "bonbon:video_id": videoId,
    "bonbon:user_id": userId,
    "bonbon:mute": mute,
  });
};

export const gtagVideoFeedbackUrlCopy = (eventId: string) => {
  window.gtag("event", "video_feedback_url_copy", {
    "bonbon:event_id": eventId,
  });
};

export const gtagVideoFeedbackTimelineItemShow = (eventId: string) => {
  window.gtag("event", "video_feedback_timeline_item_show", {
    "bonbon:event_id": eventId,
  });
};

export const gtagVideoFeedbackTimelineItemHide = (eventId: string) => {
  window.gtag("event", "video_feedback_timeline_item_hide", {
    "bonbon:event_id": eventId,
  });
};

export const gtagGoodReactionSend = (eventId: string) => {
  window.gtag("event", "good_reation_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagQuestionReactionSend = (eventId: string) => {
  window.gtag("event", "question_reation_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagReplySend = (eventId: string) => {
  window.gtag("event", "reply_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagCommentReactionSend = (eventId: string) => {
  window.gtag("event", "comment_reaction_send", {
    "bonbon:event_id": eventId,
  });
};

export const gtagTagSend = (videoId: string) => {
  window.gtag("event", "tag_send", {
    "bonbon:video_id": videoId,
  });
};
