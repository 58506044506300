import { extendTheme } from "@chakra-ui/react";

const aisekiTheme = extendTheme({
  colors: {
    aisekiNavy: {
      200: "#92a5c4",
      300: "#7285a4",
      400: "#526584",
      500: "#223554",
    },
    aisekiBlue: {
      400: "#36a6F3",
      500: "#1676F3",
      600: "#0656C3",
    },
    aisekiYellow: {
      400: "#FBDB79",
      500: "#FCCA46",
      600: "#D69E2E",
    },
    aisekiWhite: {
      500: "#ffffff",
    },
    aisekiGray: {
      100: "#f3f5f7",
      300: "#F2F3F5",
      400: "#E3E7EB",
      500: "#D3D7DB",
      600: "#C3C7CB",
    },
    aisekiBlack: {
      100: "#747F8D",
      300: "#6A7480",
      500: "#252733",
      700: "#151723",
      900: "#060607",
    },
    bonbonfrontBlue: {
      100: "#275EA6",
      200: "#233654",
      300: "#EEF2F8",
      400: "#DEE6F1",
      500: "#275EA6",
    },
  },
});

export default aisekiTheme;
