import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

interface UserInformation {
  userId: string;
  roomId: string;
  videoId: string;
}

const userInformationState = atom<UserInformation>({
  key: "userInformationState",
  default: {
    userId: "",
    roomId: "",
    videoId: "",
  },
});

export function useSetUserInformation(args: Partial<UserInformation>) {
  const setUserInformation = useSetRecoilState(userInformationState);
  useEffect(() => {
    setUserInformation((prev) => ({
      ...prev,
      ...args,
    }));
  }, [args, setUserInformation]);
}
