import { FallbackProps } from "react-error-boundary";
import ChannelFAB from "components/button/channel-fab-button";

function ErrorFallback({ error }: FallbackProps) {
  return (
    <div
      className="bg-gray-100 flex justify-center items-center h-screen w-screen relative"
      style={{ zIndex: 99999 }}
    >
      <div className="space-y-2">
        <header className="text-gray-800">ページを表示できません。</header>
        <p className="text-gray-800">
          右下のチャットボタンからお問合せください。
        </p>
      </div>
      <ChannelFAB />
    </div>
  );
}
export default ErrorFallback;
