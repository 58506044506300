import { ReactNode, useMemo } from "react";
import { I18nextProvider, initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import enCommon from "public/locales/en/common.json";
import enComponents from "public/locales/en/components.json";
import enNotifications from "public/locales/en/notifications.json";
import enOrganizations from "public/locales/en/organizations.json";
import enRooms from "public/locales/en/rooms.json";
import enStudio from "public/locales/en/studio.json";
import enVideos from "public/locales/en/videos.json";
import jaCommon from "public/locales/ja/common.json";
import jaComponents from "public/locales/ja/components.json";
import jaNotifications from "public/locales/ja/notifications.json";
import jaOrganizations from "public/locales/ja/organizations.json";
import jaRooms from "public/locales/ja/rooms.json";
import jaStudio from "public/locales/ja/studio.json";
import jaVideos from "public/locales/ja/videos.json";

export function I18nContainer({ children }: { children: ReactNode }) {
  const i18nInstance = useMemo(() => {
    // FIXME: @bonbon/feature-authでi18nのインスタンスを生成しているコードを以下にコピーしてアプリ向けの設定を加えている
    // アプリ側でi18nの設定をしつつ、@bonbon/feature-auth側でも必要な設定を共存できるような実装に修正する
    const instance = i18n.createInstance({
      ns: [
        "account",
        "common",
        "validation",
        "glossary",
        "team",
        "pafPatient",
        "pafMessage",
        "videos",
        "rooms",
        "organizations",
        "studio",
        "components",
      ],
      debug: false, // true,
      defaultNS: "account",
      load: "languageOnly",
      nonExplicitWhitelist: true,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      // keySeparator: false,
      nsSeparator: ":",
      backend: {
        loadPath:
          "https://storage.googleapis.com/porous-patient-followup-i18next/locales/{{lng}}/{{ns}}.json",
      },
      react: {
        useSuspense: false,
      },
    });

    instance.use(HttpApi).use(LanguageDetector).use(initReactI18next).init();

    instance.addResourceBundle("ja", "common", jaCommon);
    instance.addResourceBundle("ja", "studio", jaStudio);
    instance.addResourceBundle("ja", "videos", jaVideos);
    instance.addResourceBundle("ja", "rooms", jaRooms);
    instance.addResourceBundle("ja", "organizations", jaOrganizations);
    instance.addResourceBundle("ja", "notifications", jaNotifications);
    instance.addResourceBundle("ja", "components", jaComponents);
    instance.addResourceBundle("en", "common", enCommon);
    instance.addResourceBundle("en", "studio", enStudio);
    instance.addResourceBundle("en", "videos", enVideos);
    instance.addResourceBundle("en", "rooms", enRooms);
    instance.addResourceBundle("en", "organizations", enOrganizations);
    instance.addResourceBundle("en", "notifications", enNotifications);
    instance.addResourceBundle("en", "components", enComponents);

    return instance;
  }, []);

  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>;
}
