import { useRouter } from "next/router";

const INITIAL_CURRENT_PAGE = 1;
const INITIAL_PER_PAGE = 10;

export const useRoomMemberPagination = (
  initialPage: number = INITIAL_CURRENT_PAGE,
  initialPerPage: number = INITIAL_PER_PAGE
) => {
  const router = useRouter();
  const { page, per_page, room_page, room_per_page } = router.query;

  const linkPath = router.asPath.split("?")[0];
  const currentPage = +(room_page ?? initialPage);
  const perPage = +(room_per_page ?? initialPerPage);
  const offset = (currentPage - 1) * perPage;

  const additionalQuery =
    page && per_page ? `&page=${page}&per_page=${perPage}` : "";

  const onChangePage = (page: number) => {
    router.push(
      linkPath + `?room_page=${page}&room_per_page=${perPage}` + additionalQuery
    );
  };
  const onChangePerPage = (perPage: number) => {
    router.push(
      linkPath +
        `?room_page=${currentPage}&room_per_page=${perPage}` +
        additionalQuery
    );
  };
  const onNextPage = () => {
    onChangePage(currentPage + 1);
  };
  const onPrevPage = () => {
    onChangePage(currentPage - 1);
  };

  const onResetRoomPaginationData = () => {
    router.push(linkPath + additionalQuery);
  };

  return {
    currentPage,
    perPage,
    offset,
    onResetRoomPaginationData,
    onChangePage,
    onChangePerPage,
    onNextPage,
    onPrevPage,
  };
};

export const usePagination = (
  path?: string,
  initialPage: number = INITIAL_CURRENT_PAGE,
  initialPerPage: number = INITIAL_PER_PAGE
) => {
  const router = useRouter();
  const { page, per_page } = router.query;

  const linkPath = path ?? router.asPath;
  const currentPage = +(page ?? initialPage);
  const perPage = +(per_page ?? initialPerPage);
  const offset = (currentPage - 1) * perPage;

  const onChangePage = (page: number) => {
    router.push(linkPath + `?page=${page}&per_page=${perPage}`);
  };
  const onChangePerPage = (perPage: number) => {
    router.push(linkPath + `?page=${currentPage}&per_page=${perPage}`);
  };
  const onNextPage = () => {
    onChangePage(currentPage + 1);
  };
  const onPrevPage = () => {
    onChangePage(currentPage - 1);
  };

  return {
    currentPage,
    perPage,
    offset,
    onChangePage,
    onChangePerPage,
    onNextPage,
    onPrevPage,
  };
};
