import { User } from "@bonbon/data-access-auth";

export const isActiveUser = (user: User | null) => {
  if (!user) return false;
  if (!user.isEmailVerified) return false;
  if (!user.display_name) return false;
  if (!user.name) return false;

  return true;
};
