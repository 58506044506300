import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import Script from "next/script";
import { ChakraProvider } from "@chakra-ui/react";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import {
  BonbonIDProviderContainer,
  useAuthState,
} from "@bonbon/data-access-auth";
import { AuthRoute, BonbonIdFeatureContainer } from "@bonbon/feature-auth";
import DeviceErrorContainer from "components/error/device-error";
import ErrorFallback from "components/error/general-error";
import "./styles.css";
import "antd/dist/antd.css";
import "@bonbon/feature-auth/bonbon-id-feature-auth.umd.css";
import "@bonbon/feature-auth/bonbon-id-feature-auth.esm.css";
import { I18nContainer } from "components/i18n-container";
import { useSetUserInformation } from "lib/contact/useContactAction";
import { GA_TRACKING_ID, gtagPageView } from "lib/ga/gtag";
import { OrganizationContextProvider } from "lib/organization/organization-context";
import DebugObserver from "lib/util/debug-observer";
import aisekiTheme from "styles/aiseki-theme";
import "tailwindcss/tailwind.css";
import "react-color-palette/lib/css/styles.css";

function UserInformationContainer({ children }: { children: React.ReactNode }) {
  const { currentUser } = useAuthState();
  useEffect(() => {
    if (currentUser?.id) {
      gtag("set", {
        user_id: currentUser?.id,
      });
    }
  }, [currentUser?.id]);
  useSetUserInformation({
    userId: currentUser?.id,
  });
  return <>{children}</>;
}

function MyApp({ Component, pageProps }: AppProps) {
  // Initial values for routing
  const router = useRouter();
  const [loadingUserAgent, setLoadingUserAgent] = useState(true);
  const [usable, setUsable] = useState(false);
  useEffect(() => {
    if (window && window.navigator && window.navigator.userAgent) {
      setLoadingUserAgent(false);
      // (注意) device系の判定撤廃してみる
      // iOSのchrome判定
      if (navigator.userAgent.match("CriOS")) {
        setUsable(true);
      } else {
        // 一般のchrome判定
        setUsable(navigator.userAgent.indexOf("Chrome") > -1);
      }
      setLoadingUserAgent(false);
    }
  }, []);
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtagPageView(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  if (loadingUserAgent) return null;

  const continueUrl =
    router.query && router.query.continueUrl && router.query.continueUrl;
  const origin = process.env.NEXT_PUBLIC_APP_HOST;
  const url = new URL(`https://${origin}${router.asPath}`);
  const routedPath =
    router.pathname === "/_error" ? router.pathname : url.pathname;
  const searchString = url.search && url.search;
  const handleReplaceRoute = (path: string) => {
    router.replace(path);
  };
  // Initial values for hooks
  const bonbonAPIURL = process.env.NEXT_PUBLIC_BONBON_API_GATEWAY || "";
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "";
  const contactPath = process.env.NEXT_PUBLIC_CONTACT_URL || "";

  const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "",
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || "",
    databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL || "",
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "",
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || "",
    messagingSenderId:
      process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || "",
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
    measurementId: "",
  };

  return (
    <RecoilRoot>
      <DebugObserver />
      <ChakraProvider theme={aisekiTheme}>
        <BonbonIdFeatureContainer
          recaptchaSiteKey={recaptchaSiteKey}
          homePath={"/studio"}
          signInPath={"/signin"}
          signUpPath={"/signup"}
          resetPasswordPath={"/reset-password"}
          emailVerificationPath={"/email-verification"}
          registerProfilePath={"/register-profile"}
          accountSettingPath={"/account/account-setting"}
          passwordSettingPath={"/account/password-setting"}
          contactPath={contactPath}
        >
          <BonbonIDProviderContainer
            bonbonAPIURL={bonbonAPIURL}
            firebaseConfig={firebaseConfig}
          >
            <AuthRoute
              restrictedRoutes={[
                /^\/signup$/,
                /^\/signin$/,
                /^\/reset-password$/,
                /^\/terms$/,
              ]}
              publicRoutes={[/^\/dev\/.+/]}
              privateRoutes={[
                /^\/$/,
                /^\/email-verification$/,
                /^\/register-profile$/,
                /^\/account\/account-setting$/,
                /^\/account\/password-setting$/,
                /^\/videos$/,
                /^\/videos\/.+$/,
                /^\/studio$/,
                /^\/studio\/viewing$/,
                /^\/organizations\/.+$/,
                /^\/rooms$/,
                /^\/rooms\/.+$/,
                /^\/terms$/,
              ]}
              routedPath={routedPath}
              onReplace={handleReplaceRoute}
              searchString={searchString}
              continueUrl={
                typeof continueUrl === "string" ? continueUrl : undefined
              }
              isReady={router.isReady}
            >
              <I18nContainer>
                <SWRConfig
                  value={{
                    loadingTimeout: 150000,
                    shouldRetryOnError: false,
                    revalidateOnFocus: false,
                    revalidateIfStale: true,
                  }}
                >
                  <UserInformationContainer>
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      {usable ? (
                        <OrganizationContextProvider>
                          <Component {...pageProps} />
                        </OrganizationContextProvider>
                      ) : (
                        <>
                          <DeviceErrorContainer />
                        </>
                      )}
                    </ErrorBoundary>
                  </UserInformationContainer>
                </SWRConfig>
              </I18nContainer>
            </AuthRoute>
          </BonbonIDProviderContainer>
        </BonbonIdFeatureContainer>
      </ChakraProvider>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              send_page_view: false,
            });
          `,
        }}
      />
      <Script
        id="channel-setup"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function() {
            var w = window;
            if (w.ChannelIO) {
              return (window.console.error || window.console.log || function(){})('ChannelIO script included twice.');
            }
            var ch = function() {
              ch.c(arguments);
            };
            ch.q = [];
            ch.c = function(args) {
              ch.q.push(args);
            };
            w.ChannelIO = ch;
            function l() {
              if (w.ChannelIOInitialized) {
                return;
              }
              w.ChannelIOInitialized = true;
              var s = document.createElement('script');
              s.type = 'text/javascript';
              s.async = true;
              s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
              s.charset = 'UTF-8';
              var x = document.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            }
            if (document.readyState === 'complete') {
              l();
            } else if (window.attachEvent) {
              window.attachEvent('onload', l);
            } else {
              window.addEventListener('DOMContentLoaded', l, false);
              window.addEventListener('load', l, false);
            }
          })();
          ChannelIO('boot', {
            "pluginKey": "14bb7471-21ce-4ecb-9df2-8a495f35f8c1",
            "hideChannelButtonOnBoot": true
          });
          `,
        }}
      />
    </RecoilRoot>
  );
}

export default MyApp;
