import { useTranslation } from "react-i18next";
import ChannelFAB from "components/button/channel-fab-button";

const DeviceErrorContainer = () => {
  const { t } = useTranslation("common");
  return (
    <div className="inset-0 absolute bg-gray-100 flex justify-center items-center p-16 h-screen z-99999">
      <div className="space-y-2">
        <header className="text-gray-800">{t("mobileAnnouncement1")}</header>
        <p className="text-gray-800">{t("mobileAnnouncement2")}</p>
      </div>
      <ChannelFAB />
    </div>
  );
};

export default DeviceErrorContainer;
