import { ChannelIO } from "react-channel-plugin";
import { useTranslation } from "react-i18next";
import { Text, Circle, Box } from "@chakra-ui/react";
import { AiOutlineMessage } from "react-icons/ai";

const ChannelFAB = () => {
  const { t } = useTranslation();
  return (
    <Circle
      position="fixed"
      bottom={10}
      right={10}
      bg="white"
      color="bonbonfrontBlue.500"
      size="70px"
      onClick={() => ChannelIO("showMessenger")}
      shadow="lg"
      _hover={{
        background: "white",
        fontWeight: "bold",
        shadow: "2xl",
      }}
    >
      <Box className="flex flex-col items-center">
        <AiOutlineMessage aria-label="Channel" size="35px" />
        <Text fontSize="15px">{t("common:chat")}</Text>
      </Box>
    </Circle>
  );
};

export default ChannelFAB;
