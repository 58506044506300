import { useEffect } from "react";
import { useRecoilSnapshot } from "recoil";

function DebugObserver() {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      console.debug("The following atoms were modified:");
      for (const node of snapshot.getNodes_UNSTABLE({
        isModified: true,
      }) as any[]) {
        console.debug(node.key, snapshot.getLoadable(node));
      }
    }
  }, [snapshot]);

  return null;
}

export default DebugObserver;
