import { useState } from "react";
import axios from "axios";
import filetype from "magic-bytes.js";
import useSWR, { useSWRConfig } from "swr";
import { useAuthState, User } from "@bonbon/data-access-auth";
import { usePagination } from "components/pagination/hooks";
import { useOrganizationContext } from "lib/organization/organization-context";
import { Room } from "lib/room/type";
import { apiHost } from "lib/util/fetch";

export type Organization = {
  id: string;
  name: string;
  thumbnailUrl: string | undefined;
  role: OrganizationRole;
};

type OrganizationRole = "primaryOwner" | "owner" | "member" | "guest";

type FetchOrganizationsResponse = {
  organizations: Organization[];
};

export const useFetchOrganizations = () => {
  const { currentUser, idToken } = useAuthState();
  const cacheKey = currentUser?.id
    ? `https://${apiHost}/aiseki/api/organizations`
    : null;

  const { data, error } = useSWR(cacheKey, async (url) => {
    try {
      const {
        data: { organizations },
      } = await axios.get<FetchOrganizationsResponse>(url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
      return organizations;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return [];
      }
      throw e;
    }
  });
  return {
    organizations: data || [],
    isLoading: !!cacheKey && !data && !error,
    error,
  };
};

type FetchOrganizationResponse = {
  organization: Organization;
};

export const useFetchOrganization = (organizationId: string) => {
  const { currentUser, idToken } = useAuthState();
  const cacheKey = currentUser?.id
    ? `https://${apiHost}/aiseki/api/organizations/${organizationId}`
    : null;

  const { data, error } = useSWR(cacheKey, async (url) => {
    const {
      data: { organization },
    } = await axios.get<FetchOrganizationResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return organization;
  });

  return {
    organization: data,
    isLoading: !!cacheKey && !data && !error,
    error,
  };
};

// オーガにゼーンションメンバー取得API
// emailAddressはユーザがオーナー権限以上であった際には得られる。
// "guest"は403になるが型を別で持つのは嫌なのでOrganizationRoleをroleのtypeに設定した。
export type OrganizationMember = {
  bonbonUserId: string;
  role: OrganizationRole;
  emailAddress?: string;
  displayName: string;
  imageUrl: string;
};

type FetchOrganizationMembersResponse = {
  organizationMembers: OrganizationMember[];
  paging: {
    totalCount: number;
    limit: number;
    offset: number;
  };
};

export const useFetchOrganizationMembers = (
  organizationId: string | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const { currentPage, offset, perPage, onChangePerPage, onChangePage } =
    usePagination(`/organizations/${organizationId}`);
  const [totalCount, setTotalCount] = useState(0);
  const cacheKey =
    organizationId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/members?limit=${perPage}&offset=${offset}`
      : null;

  const { data, error, isValidating } = useSWR(cacheKey, async (url) => {
    const {
      data: { paging, organizationMembers },
    } = await axios.get<FetchOrganizationMembersResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    setTotalCount(paging.totalCount);
    return organizationMembers;
  });

  return {
    organizationMembers: data || [],
    isLoading: (!data && !error) || isValidating,
    error,
    offset,
    perPage,
    totalCount,
    currentPage,
    onChangePerPage,
    onChangePage,
  };
};

// オーガニゼーションメンバー更新API
type UpdateOrganizationMemberPayload = Pick<OrganizationMember, "role">;
type UpdateOrganizationMemberResponse = {
  organizationMember: OrganizationMember;
};

export const useUpdateOrganizationMember = (
  organizationId: Organization["id"] | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateOrganizationMember = (
    userId: User["id"],
    payload: UpdateOrganizationMemberPayload
  ) => {
    const cacheKey =
      organizationId && userId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/members/${userId}`
        : null;
    return mutate<OrganizationMember>(cacheKey, async (member) => {
      if (!cacheKey) return;
      setIsUpdating(true);
      const { data } = await axios.put<UpdateOrganizationMemberResponse>(
        cacheKey,
        { payload },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      mutate(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/members`
      );
      setIsUpdating(false);
      return data?.organizationMember;
    });
  };

  return {
    updateOrganizationMember,
    isUpdatingOrganizationMember: isUpdating,
  };
};

// オーガニゼーションメンバー削除API

export const useDeleteOrganizationMember = (
  organizationId: Organization["id"] | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteOrganizationMember = (userId: User["id"]) => {
    const cacheKey =
      organizationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/members`
        : null;
    return mutate<OrganizationMember[]>(cacheKey, async (members) => {
      if (!cacheKey) return;
      setIsDeleting(true);
      await axios.delete<UpdateOrganizationMemberResponse>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/members/${userId}`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsDeleting(false);
      return members?.filter((m) => m.bonbonUserId !== userId) || [];
    });
  };

  return {
    deleteOrganizationMember,
    isDeletingOrganizationMember: isDeleting,
  };
};

// オーガニゼーション作成API

type InvitationResult = {
  emailAddress: string;
  inviteStatus: "success" | "error";
  errCode: number;
  errorDetails: string;
};
type InviteOrganizationMembersResponse = {
  results: InvitationResult[];
};

type CreateOrganizationResponse = {
  organization: Organization;
};
type CreateOrganizationPayload = {
  name: string;
  logoFile?: File | undefined;
  emailAddresses: string[];
};
export const useCreateOrganization = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isCreating, setIsCreating] = useState(false);
  const [invitationResults, setInvitationResults] = useState<
    InvitationResult[]
  >([]);
  const [createdOrganization, setCreatedOrganization] =
    useState<Organization | null>(null);

  const createOrganizationMutate = (payload: CreateOrganizationPayload) => {
    return mutate<Organization[]>(
      currentUser?.id ? `https://${apiHost}/aiseki/api/organizations` : null,
      async (organizations) => {
        setIsCreating(true);
        const {
          data: { organization },
        } = await axios.post<CreateOrganizationResponse>(
          `https://${apiHost}/aiseki/api/organizations`,
          { payload: payload },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        const {
          data: { results },
        } = await axios.post<InviteOrganizationMembersResponse>(
          `https://${apiHost}/aiseki/api/organizations/${organization.id}/invitations`,
          { payload: payload },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );

        const { logoFile } = payload;
        // サムネイルアップロード
        if (logoFile) {
          const [ext] = filetype(new Uint8Array(await logoFile.arrayBuffer()));
          const { data: uploadUrl } = await axios.post<string>(
            `https://${apiHost}/aiseki/api/organizations/${organization.id}/thumbnail/uploadUrl`,
            {
              payload: {
                ext: ext.extension,
                organizationId: organization.id,
              },
            },
            { headers: { Authorization: `Bearer ${idToken}` } }
          );
          await axios.put<string>(uploadUrl, payload.logoFile, {
            headers: {
              "Content-Type": "application/octet-stream",
              Authorization: `Bearer ${idToken}`,
            },
          });
        }
        setIsCreating(false);
        setCreatedOrganization(organization);
        setInvitationResults(results);
        return [...(organizations || []), organization];
      }
    );
  };

  const cleanCreatedOrganization = () => {
    setCreatedOrganization(null);
  };

  return {
    mutate: createOrganizationMutate,
    isCreating,
    createdOrganization,
    invitationResults,
    cleanCreatedOrganization,
  };
};

type UpdateOrganizationResponse = {
  organization: Organization;
};
type UpdateOrganizationPayload = {
  name: string;
  logoFile: File | undefined;
};
export const useUpdateOrganization = (
  organizationId: Organization["id"] | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isUpdating, setIsUpdating] = useState(false);
  const [updatedOrganization, setUpdatedOrganization] =
    useState<Organization | null>(null);

  const cacheKey =
    organizationId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/organizations/${organizationId}`
      : null;

  const updateOrganizationMutate = async (
    payload: UpdateOrganizationPayload
  ) => {
    const { name, logoFile } = payload;

    return mutate<Organization>(cacheKey, async (organization) => {
      setIsUpdating(true);
      const {
        data: { organization: updatedOrganization },
      } = await axios.put<UpdateOrganizationResponse>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}`,
        { payload: { name } },
        { headers: { Authorization: `Bearer ${idToken}` } }
      );

      const { logoFile } = payload;
      // サムネイルアップロード
      if (logoFile) {
        const [ext] = filetype(new Uint8Array(await logoFile.arrayBuffer()));
        const { data: uploadUrl } = await axios.post<string>(
          `https://${apiHost}/aiseki/api/organizations/${organizationId}/thumbnail/uploadUrl`,
          {
            payload: {
              ext: ext.extension,
              organizationId: organizationId,
            },
          },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        await axios.put<string>(uploadUrl, logoFile, {
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${idToken}`,
          },
        });
      }
      setIsUpdating(false);
      setUpdatedOrganization(updatedOrganization);
      mutate(`https://${apiHost}/aiseki/api/organizations`);
      return organization;
    });
  };

  const cleanUpdatedOrganization = () => {
    setUpdatedOrganization(null);
  };

  return {
    mutate: updateOrganizationMutate,
    isUpdating,
    updatedOrganization,
    cleanUpdatedOrganization,
  };
};

type FetchOrganizationStatsResponse = {
  stats: OrganizationStats;
};

export type OrganizationStats = {
  storageSizeBytes: number;
  roomCount: number;
  details: {
    roomId: Room["roomId"];
    storageSizeBytes: number;
    videoCount: number;
  }[];
};

export const useFetchOrganizationStats = (
  organizationId: string | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const cacheKey =
    organizationId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/stats`
      : null;

  const { data, error, isValidating } = useSWR(cacheKey, async (url) => {
    const {
      data: { stats },
    } = await axios.get<FetchOrganizationStatsResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return stats;
  });

  return {
    organizationStats: data,
    isLoading: (!data && !error) || isValidating,
    error,
  };
};

// オーガニゼーション共有リンク取得API

export type OrganizationSharedLink = {
  invitationCode: string;
  availableCount: number;
  expiredAt: number;
  createdAt: number;
};

type FetchSharedLinkResponse = {
  invitation: OrganizationSharedLink;
};

export const useFetchSharedLink = (organizationId: string | undefined) => {
  const { currentUser, idToken } = useAuthState();
  const cacheKey =
    organizationId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`
      : null;

  const { data, error } = useSWR(cacheKey, async (url) => {
    const { data } = await axios.get<FetchSharedLinkResponse>(url, {
      headers: { Authorization: `Bearer ${idToken}` },
    });
    return data.invitation;
  });

  return {
    sharedLink: data,
    isLoading: !data && !error,
    error,
  };
};

// オーガニゼーション共有リンク生成API

type SharedLinkResponse = {
  result: OrganizationSharedLink;
};

export type SharedLinkPayload = {
  payload: { expiredAt: number };
};

export const useCreateSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isCreating, setIsCreating] = useState(false);

  const createSharedLink = (
    organizationId: Organization["id"],
    payload: SharedLinkPayload
  ) => {
    const cacheKey =
      organizationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`
        : null;
    return mutate<OrganizationSharedLink>(cacheKey, async () => {
      setIsCreating(true);
      const { data } = await axios.post<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`,
        payload,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsCreating(false);
      return data.result;
    });
  };

  return {
    mutate: createSharedLink,
    isCreating,
  };
};

// オーガニゼーション共有リンク更新API

export const useUpdateSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isUpdating, setIsUpdating] = useState(false);

  const updateSharedLink = (
    organizationId: Organization["id"],
    payload: SharedLinkPayload
  ) => {
    const cacheKey =
      organizationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`
        : null;
    return mutate<OrganizationSharedLink>(cacheKey, async () => {
      setIsUpdating(true);
      const { data } = await axios.put<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`,
        payload,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsUpdating(false);
      return data.result;
    });
  };

  return {
    mutate: updateSharedLink,
    isUpdating,
  };
};

// オーガニゼーション共有リンク削除API

export const useDeleteSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteSharedLink = (organizationId: Organization["id"]) => {
    const cacheKey =
      organizationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`
        : null;
    return mutate<OrganizationSharedLink | null>(cacheKey, async () => {
      setIsDeleting(true);
      await axios.delete<SharedLinkResponse>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation`,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      setIsDeleting(false);
      return null;
    });
  };

  return {
    mutate: deleteSharedLink,
    isDeleting,
  };
};

// オーガニゼーションメール招待API

type SendInvitationPayload = {
  emailAddresses: string[];
};

export const useSendInvitation = (
  organizationId: Organization["id"] | undefined
) => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const [isSending, setIsSending] = useState(false);
  const [invitationResults, setInvitationResults] = useState<
    InvitationResult[]
  >([]);

  const cacheKey =
    organizationId && currentUser?.id
      ? `https://${apiHost}/aiseki/api/organizations/${organizationId}`
      : null;

  const sendInvitationMutate = async (payload: SendInvitationPayload) => {
    return mutate<Organization>(
      cacheKey,
      async (organization) => {
        setIsSending(true);
        const {
          data: { results },
        } = await axios.post<InviteOrganizationMembersResponse>(
          `https://${apiHost}/aiseki/api/organizations/${organizationId}/invitations`,
          { payload },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        setIsSending(false);
        setInvitationResults(results);
        mutate(`https://${apiHost}/aiseki/api/organizations`);
        return organization;
      },
      false
    );
  };
  return {
    mutate: sendInvitationMutate,
    isSending,
    invitationResults,
  };
};

// オーガニゼーション招待受諾API
export const useAcceptInvitation = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const { currentOrganization } = useOrganizationContext();

  return async (organizationId: Organization["id"], invitationId: string) => {
    const cacheKey =
      organizationId && invitationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/invitations/${invitationId}`
        : null;

    return mutate<void>(cacheKey, async () => {
      await axios.post<void>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/invitations/${invitationId}`,
        null,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      await mutate([
        `https://${apiHost}/aiseki/api/rooms`,
        currentOrganization?.id,
      ]);
      mutate(`https://${apiHost}/aiseki/api/organizations`);
    });
  };
};

// オーガニゼーション公開リンク受諾API
export const useAcceptSharedLink = () => {
  const { currentUser, idToken } = useAuthState();
  const { mutate } = useSWRConfig();
  const { currentOrganization } = useOrganizationContext();

  return async (organizationId: Organization["id"], invitationId: string) => {
    const cacheKey =
      organizationId && invitationId && currentUser?.id
        ? `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation/${invitationId}`
        : null;

    return mutate<void>(cacheKey, async () => {
      await axios.post<void>(
        `https://${apiHost}/aiseki/api/organizations/${organizationId}/sharedInvitation/${invitationId}`,
        null,
        { headers: { Authorization: `Bearer ${idToken}` } }
      );
      await mutate([
        `https://${apiHost}/aiseki/api/rooms`,
        currentOrganization?.id,
      ]);
      mutate(`https://${apiHost}/aiseki/api/organizations`);
    });
  };
};
