import { createContext, useContext, useEffect } from "react";
import NextError from "next/error";
import { useRouter } from "next/router";
import { Spinner } from "@chakra-ui/react";
import { useLocalStorageState } from "ahooks";
import { useAuthState } from "@bonbon/data-access-auth";
import { isActiveUser } from "lib/auth/user";
import { Organization, useFetchOrganizations } from "./api";

type OrganizationContextType =
  | {
      currentOrganization: Organization | undefined;
      handleSwitchOrganization: (
        organizationId: Organization["id"]
      ) => void | Promise<void>;
    }
  | undefined;

export const OrganizationContext =
  createContext<OrganizationContextType>(undefined);

export const OrganizationContextProvider: React.FC = (props) => {
  const { children } = props;

  const router = useRouter();
  const { currentUser } = useAuthState();
  const [currentOrganizationId, setCurrentOrganizationId] =
    useLocalStorageState<Organization["id"]>(
      `tsucom-current-organization:${currentUser?.id}`
    );
  const { organizations, isLoading, error } = useFetchOrganizations();

  useEffect(() => {
    // MEMO: オーガニゼーション未所属の場合はオンボーディングページへリダイレクト
    // ※ ただし、メール認証やプロフィール登録が済んでいない場合は、BonBonID側で
    // 適切なページへリダイレクトするため、ここでのリダイレクトは行わない
    if (isActiveUser(currentUser) && !isLoading && organizations.length < 1) {
      router.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isLoading, organizations]);

  if (error) {
    return <NextError statusCode={500} />;
  }
  // TODO: スピナーを統一できるようにbonbon-frontを改修すること
  if (isLoading) {
    return (
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
        position="fixed"
        top="45%"
        left="45%"
      />
    );
  }

  const currentOrganization =
    organizations.find((o) => o.id === currentOrganizationId) ||
    organizations[0];

  const handleSwitchOrganization = (organizationId: Organization["id"]) => {
    setCurrentOrganizationId(organizationId);
  };

  return (
    <OrganizationContext.Provider
      value={{
        currentOrganization: currentOrganization,
        handleSwitchOrganization: handleSwitchOrganization,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrganizationContext = () => {
  const contextValue = useContext(OrganizationContext);

  if (!contextValue) {
    throw new Error(
      "useOrganizationContext must be used within OrganizationContextProvider."
    );
  }

  return contextValue;
};
